import React from "react";
import { injectIntl } from "react-intl";
import { Link } from "react-router-dom";

import Button from "../../components/Button";

import { calcColumnWidth } from "../../utils/layout";
import { isRelative } from "../../utils/api";

const ErrorPageButton = ({ intl }) => {
  return (
    <Button
      as={!isRelative(intl.formatMessage({ id: "url.login" })) ? "a" : Link}
      to="/"
      href={intl.formatMessage({ id: "url.login" })}
      text={intl.formatMessage({ id: "log_in" })}
      width={`calc(${calcColumnWidth(2, 6, "end", true)} - 16px)`}
    />
  );
};

export default injectIntl(ErrorPageButton);
