import React from "react";
import styled from "styled-components";
import { injectIntl } from "react-intl";

import Logo from "./Logo";

const Header = styled.header`
  display: flex;
  height: 164px;
  justify-content: flex-end;
  padding: 56px 56px 0 0;
  box-sizing: border-box;

  @media only screen and (max-width: 1280px) {
    height: 124px;
  }

  @media only screen and (max-width: 940px) {
    justify-content: flex-start;
    padding: 24px 56px 0 0;
  }
`;

const Link = styled.a`
  text-decoration: none;
`;

const NavBar = ({ className, color, intl }) => {
  return (
    <Header className={className}>
      <Link
        href={intl.formatMessage({ id: "url.logo_url" })}
      >
        <Logo color={color}/>
      </Link>
    </Header>
  );
};

export default injectIntl(NavBar);
