import styled from "styled-components";

import { css, SECTION } from "Utils/theme";

const Error = styled.div`
  ${css(SECTION, "red", "color")};
  margin-top: 8px;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  &::before {
    content: "• ";
  }
`;

export default Error;
