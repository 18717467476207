import styled from "styled-components";

import { calcColumnWidth } from "../../utils/layout";

import LeftP from "../../components/LeftPane";
import Button from "../../components/Button";

export const LeftPane = styled(LeftP)`
  padding: 243px 0 0 0;

  @media only screen and (max-width: 1280px) {
    padding-top: 160px;
  }
`;

export const RightPaneLayout = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LeftPaneTitle = styled.div`
  padding: 0 0 86px 0;
  text-align: center;

  padding-left: ${() => calcColumnWidth(2, 0, "start")};
  padding-right: ${() => calcColumnWidth(2, 0, "start")};

  img {
    width: 211px;
  }
  p {
    margin-bottom: 0;
  }

  @media only screen and (max-width: 1280px) {
    padding-left: ${() => calcColumnWidth(1, 0, "start")};
    padding-right: ${() => calcColumnWidth(1, 0, "start")};
  }
`;

export const LeaderFall = styled.p`
  margin-top: 40px;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
`;

export const GirlsWhoCode = styled.section`
  height: 100%;
  margin-right: 1px;
  padding: 60px 0;
  background: #fff;
  text-align: center;
  font-size: 15px;
  line-height: 24px;
  font-weight: bold;
`;

export const ReviewBtn = styled(Button)`
  background: #ffc9c6;
  color: #1a192b;

  &:hover {
    background-color: rgba(255, 201, 198, 0.5);
  }
`;
