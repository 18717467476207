import React from "react";
import { injectIntl } from "react-intl";
import { Link } from "react-router-dom";

import FullPageError from "../../components/FullPageError";
import Button from "../../components/Button";

import { calcColumnWidth } from "../../utils/layout";

const InvitationError = ({ intl }) => {
  let url = "/register";
  let isInvitation = false;

  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has("email_address") && urlParams.has("sid")) {
    url += `?email_address=${encodeURIComponent(
      urlParams.get("email_address")
    )}&sid=${urlParams.get("sid")}`;
    isInvitation = true;
  }

  return (
    <FullPageError
      title={intl.formatMessage({ id: "invitation_error_title" })}
      message={intl.formatMessage({ id: "invitation_error_desc" })}
    >
      {isInvitation ? (
        <Button
          as={Link}
          to={url}
          text={intl.formatMessage({ id: "sign_up" })}
          width={`calc(${calcColumnWidth(2, 6, "end", true)} - 16px)`}
        />
      ) : (
        <React.Fragment>
          <Button
            as="a"
            marginRight="8px"
            href={`${window.AUTH_USER_REDIRECT_URL}logout`}
            text={intl.formatMessage({ id: "log_out" })}
            width={`calc(${calcColumnWidth(2, 6, "end", true)} - 16px)`}
          />
          <Button
            as="a"
            href={window.AUTH_USER_REDIRECT_URL}
            text={intl.formatMessage({ id: "back_to_console" })}
            width={`calc(${calcColumnWidth(2, 6, "end", true)} - 16px)`}
          />
        </React.Fragment>
      )}
    </FullPageError>
  );
};

export default injectIntl(InvitationError);
