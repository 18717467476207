import React from "react";
import ReactSelect from "react-select";
import styled from "styled-components";

import Error from "./Error";
import Label from "./Label";
import FormRequired from "./FormRequired";
import { calcColumnWidth } from "../../utils/layout";

const Layout = styled.div`
  width: calc(${calcColumnWidth(3, 6, "end", true)} - 16px);
  max-width: 318px;
  box-sizing: border-box;
  margin-bottom: 24px;
  font-size: 15px;
`;

const customStyles = {
  input: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#ffffff" : "#f5f7fa"
  }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#ffffff" : "#f5f7fa",
    borderRadius: "2px",
    border: "none",
    boxShadow: state.isFocused && "0 0 0 1px #98A0AB"
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: state.isFocused ? "#145CC6 !important" : "#4A495E",
    transform: state.isFocused && "rotate(-180deg)"
  }),
  menuList: provided => ({
    ...provided,
    backgroundColor: "#ffffff",
    fontWeight: "normal",
    fontSize: "15px",
    lineHeight: "16px",
    color: "#4B6180",
    padding: "16px 4px",
    borderRadius: "2px",
    boxShadow: "0px 4px 8px rgba(152, 160, 171, 0.4)",
    border: "none"
  }),
  indicatorSeparator: () => ({
    display: "none"
  }),
  placeholder: provided => ({
    ...provided,
    color: "#98A0AB",
    lineHeight: "20px",
    fontSize: "15px",
    fontWeight: "normal"
  }),
  valueContainer: provided => ({
    ...provided,
    paddingLeft: "16px",
    fontWeight: "normal"
  }),
  option: provided => ({
    ...provided,
    borderRadius: "4px"
  })
};

const Select = ({ error, label, required, ...props }) => {
  return (
    <Layout>
      <Label error={error}>
        <div>
          {label}
          {required && (
            <FormRequired error={error} title="This field is required.">
              *
            </FormRequired>
          )}
        </div>
      </Label>
      <ReactSelect styles={customStyles} {...props} />
      {error && <Error>{error}</Error>}
    </Layout>
  );
};

export default Select;
