import React from "react";

import Error from "./fields/Error";

const GeneralError = ({ error }) => {
  if (!error) return false;

  if (error.detail) {
    const errorDetailNumber = Object.keys(error.detail).length;

    if (
      errorDetailNumber > 1 ||
      (errorDetailNumber === 1 && !error.detail.errors)
    ) {
      return false;
    }
  }

  return <Error>{error.message}</Error>;
};

export default GeneralError;
