import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { injectIntl } from "react-intl";

import BodyFooterLayout from "../../components/BodyFooterLayout";
import SplitLayout from "../../components/SplitLayout";
import LeftPane from "../../components/LeftPane";
import NavBar from "../../components/NavBar";
import Meta from "../../components/Meta";
import MobileArt from "../../components/MobileArt";
import Title from "../../components/Title";
import Text16 from "../../components/Text16";
import Button from "../../components/Button";

import { requireImage } from "Utils/assets";

const ResetPasswordSuccessImage = requireImage("password_reset_desktop.png");
const passwordResetImg = requireImage("password_reset_mobile.png");

const RightPaneLayout = styled.div`
  display: flex;
  flex-direction: column;

  a:after {
    display: none;
  }
`;

const Container = styled.div`
  @media only screen and (max-width: 900px) {
    padding-top: 100px;
  }
`;

const HeadMobileArt = styled(MobileArt)`
  width: 100%;
  max-width: 500px;
  height: 160px;
  left: 0px;
`;

const LeftPaneLayout = styled(LeftPane)`
  padding-top: 240px;
`;

const SignupError = ({ intl }) => (
  <SplitLayout>
    <Meta title={intl.formatMessage({ id: "email_confirmation" })} />
    <LeftPaneLayout backgroundImage={ResetPasswordSuccessImage} />
    <RightPaneLayout>
      <NavBar color="black" />
      <Container>
        <BodyFooterLayout marginTop="120px" hideSignUpText={true}>
          <HeadMobileArt src={passwordResetImg} />
          <Title>{intl.formatMessage({ id: "email_success_change" })}</Title>
          <Text16 margin="8px 0 80px 0">
            {intl.formatMessage({ id: "can_use_new_email" })}
          </Text16>
          <Button as={Link} to="/">
            {intl.formatMessage({ id: "log_in" })}
          </Button>
        </BodyFooterLayout>
      </Container>
    </RightPaneLayout>
  </SplitLayout>
);

export default injectIntl(SignupError);
