import React from "react";
import styled from "styled-components";
import { injectIntl } from "react-intl";
import { Link } from "react-router-dom";

import { calcColumnWidth } from "../utils/layout";

const Layout = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: ${() => calcColumnWidth(2, 0, "start")};
  margin-top: ${props => props.marginTop};
  background: white;

  @media only screen and (max-width: 940px) {
    margin-top: 32px;
    padding-left: 0;
  }
  @media only screen and (max-height: 768px) {
    margin-top: ${props => props.marginMobile};
  }
`;

const AlreadyHaveAccountText = styled.span`
  @media only screen and (max-width: 940px) {
    display: none;
  }
`;

const Footer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 189px;
  padding-bottom: 32px;
  font-size: 15px;

  div::first-letter {
    text-transform: uppercase;
  }

  div + div {
    margin-top: 24px;
  }

  @media only screen and (max-width: 940px) {
    font-size: 12px;
    div + div {
      margin-top: 8px;
    }
  }

  @media only screen and (max-height: 940px) {
    padding: 40px 0;
    justify-content: flex-end;
  }
`;

const VerticalSeparator = styled.span`
  color: #c9d0e4;
  margin: 0 10px;
  font-size: 16px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  height: 100%;
  justify-content: space-between;

  @media only screen and (max-height: 600px) {
    justify-content: flex-start;
  }
`;

const AlreadyHaveAccount = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 33px;
  color: #5f5e70;

  @media only screen and (min-width: 940px) {
    display: block;
    line-height: 33px;
  }
`;

const SignUpText = styled.div`
  color: #98a0ab;
  font-weight: normal;
  display: none;

  @media only screen and (min-width: 940px) {
    display: block;
    line-height: 33px;
  }
`;

const SignUpTextMobile = styled.div`
  color: #98a0ab;
  font-weight: normal;
  display: none;

  @media only screen and (max-width: 940px) {
    display: block;
  }
`;

const LogInLink = styled(Link)`
  @media only screen and (max-width: 940px) {
    display: none;
  }
`;

const Children = styled.div`
  width: 100%;
`;

const BodyFooterLayout = ({
  className,
  children,
  intl,
  marginTop,
  marginMobile,
  showAlreadyHaveAccountLink,
  showDontHaveAccountLink,
  hideSignUpText
}) => (
  <Layout
    className={className}
    marginTop={marginTop}
    marginMobile={marginMobile}
  >
    <Wrapper>
      <Children>{children}</Children>
      <Footer>
        {showAlreadyHaveAccountLink && (
          <AlreadyHaveAccount>
            <AlreadyHaveAccountText>
              {intl.formatMessage({ id: "already_have_account" })}{" "}
            </AlreadyHaveAccountText>
            <LogInLink to="/">{intl.formatMessage({ id: "log_in" })}</LogInLink>
          </AlreadyHaveAccount>
        )}
        <SignUpText>
          {showDontHaveAccountLink && (
            <AlreadyHaveAccount>
              {intl.formatMessage({ id: "dont_have_account" })}{" "}
              <Link to="/register">
                {intl.formatMessage({ id: "sign_up" })}
              </Link>
            </AlreadyHaveAccount>
          )}
          {!hideSignUpText
            ? intl.formatMessage({ id: "by_signing_up_you_agree" })
            : false}{" "}
          <a href="https://platform.sh/tos/">
            {intl.formatMessage({ id: "terms_conditions" })}
          </a>{" "}
          <VerticalSeparator>|</VerticalSeparator>{" "}
          <a href="https://chat.platform.sh/">
            {intl.formatMessage({ id: "help_support" })}
          </a>
          <VerticalSeparator>|</VerticalSeparator>{" "}
          <a href="https://platform.sh/privacy-policy/">
            {intl.formatMessage({ id: "privacy_policy" })}
          </a>
        </SignUpText>

        <SignUpTextMobile>
          {showDontHaveAccountLink && (
            <AlreadyHaveAccount>
              {intl.formatMessage({ id: "dont_have_account" })}{" "}
              <Link to="/register">
                {intl.formatMessage({ id: "sign_up" })}
              </Link>
            </AlreadyHaveAccount>
          )}
          {!hideSignUpText ? (
            <React.Fragment>
              <div>
                <Link to="/">{intl.formatMessage({ id: "log_in" })}</Link>
                <VerticalSeparator>|</VerticalSeparator>{" "}
                <a href="https://chat.platform.sh/">
                  {intl.formatMessage({ id: "help_support" })}
                </a>
              </div>
              <div>
                {intl.formatMessage({ id: "by_signing_up_you_agree" })}{" "}
                <a href="https://platform.sh/tos/">
                  {intl.formatMessage({ id: "terms_conditions" })}
                </a>{" "}
                <VerticalSeparator>|</VerticalSeparator>{" "}
                <a href="https://platform.sh/privacy-policy/">
                  {intl.formatMessage({ id: "privacy_policy" })}
                </a>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <a href="https://platform.sh/tos/">
                {intl.formatMessage({ id: "terms_conditions" })}
              </a>{" "}
              <VerticalSeparator>|</VerticalSeparator>{" "}
              <a href="https://chat.platform.sh/">
                {intl.formatMessage({ id: "help_support" })}
              </a>
              <VerticalSeparator>|</VerticalSeparator>{" "}
              <a href="https://platform.sh/privacy-policy/">
                {intl.formatMessage({ id: "privacy_policy" })}
              </a>
            </React.Fragment>
          )}
        </SignUpTextMobile>
      </Footer>
    </Wrapper>
  </Layout>
);

export default injectIntl(BodyFooterLayout);
