import React from "react";
import styled from "styled-components";

import Loading from "./Loading";

import { calcColumnWidth } from "../../utils/layout";

const Layout = styled.div`
  width: ${props =>
    props.width || `calc(${calcColumnWidth(3, 6, "end", true)} - 16px)`};
  max-width: 318px;

  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 940px) {
    max-width: 500px;
    width: ${() => calcColumnWidth(3, 6, "end", true)};
  }
`;

const LoadingWrapper = ({ children, isLoading }) => (
  <Layout>{isLoading ? <Loading /> : children}</Layout>
);

export default LoadingWrapper;
