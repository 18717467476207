import React from "react";
import styled from "styled-components";

import { calcColumnWidth } from "../utils/layout";

import { css, BUTTON } from "Utils/theme";

const BaseButton = styled.button`
  box-sizing: border-box;
  height: 42px;
  padding: 0 14px;
  text-align: center;
  border-radius: 2px;
  ${css(BUTTON, "brand-blue", "background-color")};
  ${css(BUTTON, "snow", "color")};
  line-height: 40px;
  font-weight: 600;
  font-size: 15px;
  border: none;
  cursor: pointer;
  font-family: Open Sans;
  transition: 0.2s background-color;
  margin-right: ${props => props.marginRight};

  &::first-letter {
    text-transform: uppercase;
  }

  &:hover {
    ${css(BUTTON, "skye", "background-color")};
  }

  @media only screen and (max-width: 940px) {
    font-size: 14px;
  }

  &:after {
    display: none;
  }
`;

const DefaultButton = styled(BaseButton)`
  width: ${props =>
    props.width || `calc(${calcColumnWidth(3, 6, "end", true)} - 16px)`};
  max-width: 318px;
  padding: 0;

  @media only screen and (max-width: 940px) {
    max-width: 500px;
    width: ${props => props.width || calcColumnWidth(3, 6, "end", true)}};
  }
`;

const ButtonComponent = props => {
  let Button = DefaultButton;

  return (
    <Button disabled={props.isLoading} {...props}>
      {props.text || props.children}
    </Button>
  );
};

export default ButtonComponent;
