import styled from "styled-components";

const Art = styled.img`
  width: 180px;
  height: 85px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;

  @media only screen and (min-width: 941px) {
    display: none;
  }
`;

export default Art;
