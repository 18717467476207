
/* Mostly copied from console src/libs/themes/index.js 
   TODO: Find a way to share code
*/
export const NAVBAR = "navbar";
export const SECTION = "section";
export const MENU = "menu";
export const MENU_ITEM = "menu-item";
export const LINK = "link";
export const BUTTON = "button";
export const ICON = "icon";
export const INPUT = "input";
export const HEADER = "header";
export const TAB = "tab";
export const MODAL = "modal";
export const TOOLTIP = "tooltip";

// Function to use in the styled component when the css property need to be themable.
// componentName is a uniq specific name that describe the current component
// componentType need the be one of the const above
// cssProperty is the property you want to set
// fallback is the fallback variable you want to use if no variable are setted
export const css = (componentType, fallback, cssProperty, componentName) => {
  let cssString = "";

  if (cssProperty) {
    cssString += `${cssProperty}:`;
  }

  cssString += `${getCSSVarString(
    componentType,
    fallback,
    cssProperty,
    componentName
  )}`;

  return cssString;
};

export function generateColorVariableNames(
  componentName,
  componentType,
  cssProperty,
  fallback
) {
  let variableNames = [`${componentType}-${fallback}`, `${fallback}`];

  if (cssProperty) {
    variableNames.unshift(`${componentType}-${fallback}-${cssProperty}`);
  }

  if (componentName) {
    variableNames.unshift(`${componentName}-${componentType}-${cssProperty}`);
  }

  return variableNames;
}

export const getCSSVarString = (
  componentType,
  fallback,
  cssProperty,
  componentName
) => {
  const variableNames = generateColorVariableNames(
    componentName,
    componentType,
    cssProperty,
    fallback
  );

  let cssVarArray = variableNames.map(v => `var(--${v}`);

  let css = cssVarArray.join(",");
  for (let i = 0; i < cssVarArray.length; i++) {
    css += ")";
  }

  return css;
};
// `var(--${componentName}-${componentType}-${cssProperty}, var(--${componentType}-${cssProperty}, var(--${fallback})))`;

export const getCSSVariable = (cssVar, fallback = "#000000") => {
  const style = getComputedStyle(document.documentElement);
  return style.getPropertyValue(cssVar) || fallback;
};

export const getColor = (
  componentName,
  componentType,
  cssProperty,
  fallback
) => {
  const variableNames = generateColorVariableNames(
    componentName,
    componentType,
    cssProperty,
    fallback
  );

  const style = getComputedStyle(document.documentElement);
  const availableVar = variableNames.find(v =>
    style.getPropertyValue(`--${v}`)
  );

  return style.getPropertyValue(`--${availableVar}`);
};

export const setCurrentTheme =(modeName = "light") => {
  const html = document.querySelector("html");
  html.setAttribute("data-theme", modeName);
}
