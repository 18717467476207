import styled from "styled-components";

import { calcColumnWidth } from "../utils/layout";

const Title = styled.h1`
  font-family: Moderat;
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  line-height: ${props => props.lineHeight || "33px"};
  color: #1a192b;

  max-width: ${() => calcColumnWidth(4, 6, "start", true)};

  margin: ${props => props.margin || "24px 0"};

  &::first-letter {
    text-transform: uppercase;
  }

  @media only screen and (max-width: 940px) {
    font-size: 20px;
    margin: ${props => props.marginMobile || "16px 0"};
  }
`;

export default Title;
