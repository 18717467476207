import styled from "styled-components";

import { css, SECTION } from "Utils/theme";

const FormRequired = styled.span`
  font: 0/0 none;
  height: 4px;
  width: 4px;
  border-radius: 4px;
  ${css(SECTION, "brand-blue", "background-color")};
  display: inline-block;
  position: relative;
  bottom: 6px;
  left: 5px;

  ${props => {
    if (props.error) {
      return `
        ${css(SECTION, "red", "background-color")};
			`;
    }
  }}
`;

export default FormRequired;
