export const redirectIfAuthenticated = () => {
  if (document.cookie && document.cookie.includes("skip_login_form")) {
    window.location.href = window.AUTH_USER_REDIRECT_URL;
    return true;
  }

  return false;
};


let tracking = new URLSearchParams();

export const setTracking = t => {
  tracking = t;
};

export const getTracking = () => {
  return tracking;
};