import styled from "styled-components";

const Text16 = styled.div`
  margin: ${props => props.margin};
  &::first-letter {
    text-transform: uppercase;
  }
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;

  max-width: 440px;

  @media only screen and (max-width: 900px) {
    line-height: 24px;
    font-size: 14px;
  }
`;

export default Text16;
