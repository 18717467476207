import styled from "styled-components";

const SplitLayout = styled.div`
  display: grid;
  z-index: 3;
  grid-template-columns: minmax(100px, 50%) minmax(100px, 50%);

  height: 100%;
  max-height: 2000px;

  @media only screen and (min-width: 1680px),
    (max-width: 940px) and (min-width: 600px) {
    background-color: #ffffff;
    max-width: 1920px;
    box-shadow: 0px 6px 44px rgba(152, 160, 171, 0.2);
    margin-left: auto;
    margin-right: auto;
    position: relative;
  }

  @media only screen and (max-width: 940px) {
    grid-template-columns: 100%;
    min-height: unset;
  }

  @media only screen and (max-width: 940px) and (orientation: landscape) {
    grid-template-columns: 100%;
    min-height: 800px;
  }

  @media only screen and (max-width: 940px) and (min-width: 600px) {
    grid-template-columns: 100%;
    max-width: 500px;
    padding: 0 24px;
  }
`;

export default SplitLayout;
