import React from "react";
import styled from "styled-components";
import { injectIntl } from "react-intl";

import { calcColumnWidth } from "../../utils/layout";
import { getSubmitConsentUrl } from "../../utils/api";
import { css, BUTTON, getCSSVarString } from "Utils/theme"; 
import { requireImage } from "Utils/assets";

import Button from "../../components/Button";

import Logo from "../../components/Logo";

const Layout = styled.div`
  background-color: white;
  position: absolute;
  height: 100%;
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  width: ${() => calcColumnWidth(6, 6, "end")};

  button + button {
    margin-left: 32px;
  }

  @media only screen and (max-width: 940px) {
    margin-top:32px;
    margin-left: 0;
    margin-right: 0;
    align-items: start;

    button + button {
      margin-left: 16px;
    }

    img {
      width: 100px;
    }
  }
`;

const Title = styled.div`
  font-family: Moderat;
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  line-height: 33px;
  margin-bottom: 60px;
  text-align: center;
  @media only screen and (max-width: 940px) {
    text-align: left;
  }
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
`;

const CancelButton = styled(Button)`
  border: 1px solid ${getCSSVarString(BUTTON, "stone", "border-color")};
  ${css(BUTTON, "transparent", "background-color")};
  ${css(BUTTON, "brand-blue", "color")};

  &:hover {
    ${css(BUTTON, "transparent", "background-color")};
  }
`;

const Link = styled.a`
  margin-left: auto;
  margin-right: auto;
  text-decoration: none;
  margin-bottom: 100px;
  @media only screen and (max-width: 940px) {
    margin-bottom: 40px;
    margin-left: 0;
    margin-right: 0;
  }
`;

const ConsentArtImg = styled.img`
  width: 291px;
  margin-bottom: 64px;
`;

const Consent = ({ intl }) => {
  const urlParams = new URLSearchParams(window.location.search);

  const client = urlParams.get("client");

  const accept = () => {
    document.getElementById("grant-input").value = "accepted";
  };

  const consentArt = requireImage("authorize_spot.png");

  return (
    <Layout>
      <Content>
        <Link
          href={intl.formatMessage({ id: "url.logo_url" })}
        >
          <Logo color="black" size={177} />
        </Link>
        {consentArt && <ConsentArtImg width="291" src={consentArt} />}
        <Title>
          {intl.formatMessage(
            { id: "consent_description" },
            { client: client || process.env.REACT_APP_CLIENT_NAME }
          )}
        </Title>
        <form action={getSubmitConsentUrl()} method="post">
          <input type="hidden" name="csrf" value={urlParams.get("csrf")} />
          <input
            type="hidden"
            name="scope"
            value={urlParams.get("scope") || ""}
          />
          <input type="hidden" name="grant" id="grant-input" value="refused" />
          <Buttons>
            <Button width={calcColumnWidth(2, 3, "start")} onClick={accept} autoFocus>
              {intl.formatMessage({ id: "yes_authorize" })}
            </Button>
            <CancelButton width={calcColumnWidth(2, 3, "start")}>
              {intl.formatMessage({ id: "cancel" })}
            </CancelButton>
          </Buttons>
        </form>
      </Content>
    </Layout>
  );
};

export default injectIntl(Consent);
