import React from "react";
import { injectIntl } from "react-intl";

import FullPageError from "../../components/FullPageError";
import Button from "./ErrorPageButton";

import { ERRORS } from "../../utils/constants";

const CustomError = ({ intl }) => {
  const urlParams = new URLSearchParams(window.location.search);
  let title = intl.formatMessage({ id: "internal_server_title" });
  let description = intl.formatMessage({ id: "internal_server_desc" });

  if (urlParams.has("code") && urlParams.get("code") !== "") {
    const error = ERRORS[urlParams.get("code")];
    if(error) {
      title = error.title;
      description = error.message;
    }
  }

  return (
    <FullPageError title={title} message={description}>
      <Button />
    </FullPageError>
  );
};

export default injectIntl(CustomError);
