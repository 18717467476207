export const requireImage = (imageName) => {
    if(process.env.VENDOR_URL) {
        try {
            let image = require(`Vendor_resources/images/${imageName}`);
            return image;
        } catch(err){
            return false;
        }
    } else {
        let image = require(`Images/${imageName}`);
        return image;
    }
};

export const requireVideo = (imageName) => {
    if(process.env.VENDOR_URL) {
        try {
            let image = require(`Vendor_resources/videos/${imageName}`);
            return image;
        } catch(err){
            return false;
        }
    } else {
        let image = require(`Videos/${imageName}`);
        return image;
    }
};
