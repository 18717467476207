import styled from "styled-components";

import { css, SECTION } from "Utils/theme";

const Label = styled.label`
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  max-width: 100%;
  margin-bottom: 8px;
  text-align: left;
  color: #5f5e70;
  width: 318px;

  &::first-letter {
    text-transform: uppercase;
  }

  ${props => {
    if (props.error) {
      return `
        ${css(SECTION, "red", "color")};
			`;
    }
  }}
`;

export default Label;
