import React from "react";

import LoadingWrapper from "./LoadingWrapper";
import Button from "../Button";

const ButtonLoading = ({ isLoading, ...props }) => (
  <LoadingWrapper isLoading={isLoading}>
    <Button isLoading={isLoading} {...props} />
  </LoadingWrapper>
);

export default ButtonLoading;
