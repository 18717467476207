import React from "react";
import { injectIntl } from "react-intl";

import FullPageError from "../../components/FullPageError";
import Button from "./ErrorPageButton";

const InternalServerError = ({ intl }) => {
  return (
    <FullPageError
      title={intl.formatMessage({ id: "internal_server_title" })}
      message={intl.formatMessage({ id: "internal_server_desc" })}
    >
      <Button />
    </FullPageError>
  );
};

export default injectIntl(InternalServerError);
