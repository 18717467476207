import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { requireImage } from "Utils/assets";
import { css, SECTION } from "Utils/theme";

import Logo from "./Logo";

const backgroundLarge = requireImage("404-lg.png");
const backgroundSmall = requireImage("404-sm.png");

const ErrorMessagePageWrapper = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 10;

  ${() => {
    if (!backgroundLarge || !backgroundSmall) {
      return `
        ${css(SECTION, "smoke", "background-color")};
        ${css(SECTION, "night", "color")};
      `;
    }
    
    return `
      background-image: url(${backgroundSmall});
      background-repeat: no-repeat;
      background-position: top center;
      background-size: contain;
      ${css(SECTION, "smoke", "background-color")};
      ${css(SECTION, "night", "color")};
      
      @media screen and (min-width: 900px) {
        background-image: url(${backgroundLarge});
        background-size: cover;
      }`;
  }};
`;

const HeaderWrapper = styled.div`
  margin-top: 32px;
  margin-left: 24px;

  svg {
    height: 38px;
    width: auto;
  }

  @media only screen and (min-width: 900px) {
    margin-left: 156px;
  }
`;

const TextWrapper = styled.div`
  margin-top: 54px;
  margin-left: 24px;
  margin-right: 24px;
  max-height: 60vh;
  z-index: 2;
  position: relative;
  max-width: 662px;

  .code {
    margin: 32px 0 16px;
    overflow: hidden;

    pre {
      font-family: AndaleMono;
      font-size: 12px;
      line-height: 14px;
      background: #1a192b;
      max-height: 354px;
      color: #ffffff;
      width: 100%;
      max-width: 100%;
      box-sizing: border-box;
      white-space: pre;
      margin: 0;
      padding: 8px 16px;
      text-align: left;
      border-radius: 2px;
      overflow: scroll;
    }
  }

  .actions {
    margin-top: 48px;
    display: flex;
    align-items: center;

    a.button {
      background-color: rgb(22, 100, 255);
      color: rgb(255, 255, 255);
      border-radius: 2px;
    }

    button,
    a {
      margin-right: 16px;
      margin-top: 0;
      font-size: 15px;
      line-height: 20px;
    }

    .outline {
      color: #4a495e;
    }
  }

  @media only screen and (min-width: 900px) {
    margin-top: 20vh;
    margin-left: 156px;
    max-height: 80vh;

    pre {
      max-height: 120px;
    }

    .actions {
      margin-top: 64px;
    }
  }
`;

export const CodeWrapper = styled.pre`
  padding: 32px;
  max-width: 100%;
  overflow: hidden;
`;

const Heading = styled.h1`
  font-weight: bold;
  font-size: 20px;
  line-height: 22px;
  color: #0e0d34;
  margin-bottom: 24px;
  margin-top: 0;

  @media only screen and (min-width: 900px) {
    font-size: 32px;
    line-height: 22px;
    color: #1a192b;
    margin-bottom: 32px;
  }
`;

const Message = styled.p`
  font-size: 14px;
  line-height: 24px;
  color: #4a495e;

  @media only screen and (min-width: 900px) {
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #1a192b;
  }
`;


const FullPageError = ({ children, title, message, errorCode, ...props }) => (
  <ErrorMessagePageWrapper {...props}>
    <HeaderWrapper>
      <Link className="logo" to={"/"}>
        <Logo color="black"/>
      </Link>
    </HeaderWrapper>
    <TextWrapper>
      <Heading>{title}</Heading>
      {message && <Message>{message}</Message>}
      {errorCode && <CodeWrapper>{errorCode}</CodeWrapper>}
      {children}
    </TextWrapper>
  </ErrorMessagePageWrapper>);

export default FullPageError;
