import React from "react";
import { injectIntl } from "react-intl";

import FullPageError from "../../components/FullPageError";
import Button from "./ErrorPageButton";

const ErrorPage = ({ intl }) => {
  return (
    <FullPageError title={intl.formatMessage({ id: "not_found_title" })}>
      <Button />
    </FullPageError>
  );
};

export default injectIntl(ErrorPage);
