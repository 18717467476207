import React from "react";
import styled from "styled-components";
import { injectIntl } from "react-intl";

import Title from "./Title";
import { calcColumnWidth } from "../utils/layout";

import checkIcon from "../images/big_tick.svg";

const Layout = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 300px;
  color: #302f45;
  background-image: url(${props => props.backgroundImage});
  background-size: cover;
  background-color: #f4f2f3;
  height: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 28px;
  padding-left: ${() => calcColumnWidth(2, 0, "start")};
  padding-right: ${() => calcColumnWidth(2, 0, "start")};
  box-sizing: border-box;

  div::first-letter {
    text-transform: uppercase;
  }

  @media only screen and (max-width: 940px) {
    display: none;
  }

  @media only screen and (max-width: 1080px) {
    padding-left: ${() => calcColumnWidth(1, 0, "start")};
    padding-right: ${() => calcColumnWidth(1, 0, "start")};
    padding-top: 215px;
  }

  @media only screen and (max-height: 768px) {
    padding-top: 150px;
  }
`;

const Description = styled.div``;

const LeftPaneTitle = styled(Title)`
  margin-top: 0;

  @media only screen and (max-height: 768px) {
    font-size: 25px;
    line-height: 20px;
    margin-top: 0;
  }
`;

const ListTitle = styled.div`
  margin-top: 10px;

  @media only screen and (max-height: 768px) {
    font-size: 13px;
    margin-top: 0;
  }
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  font-weight: normal;
`;

const ListItem = styled.div`
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 28px;

  @media only screen and (max-height: 768px) {
    font-size: 13px;
  }

  img {
    margin-right: 8px;
  }
`;

const ListItemText = styled.span`
  &::first-letter {
    text-transform: uppercase;
  }
`;

const LeftPane = ({
  header,
  title,
  description,
  listTitle,
  list = [],
  body = false,
  children,
  backgroundImage,
  ...props
}) => {
  return (
    <Layout backgroundImage={backgroundImage} {...props}>
      {header}
      {title && <LeftPaneTitle lineHeight="40px">{title}</LeftPaneTitle>}
      {description && <Description>{description}</Description>}
      {listTitle && <ListTitle>{listTitle}</ListTitle>}
      {list.length > 0 && (
        <List>
          {list.map(item => (
            <ListItem key={item}>
              <img alt="checked" src={checkIcon} />
              <ListItemText>{item}</ListItemText>
            </ListItem>
          ))}
        </List>
      )}
      {children || body}
    </Layout>
  );
};

export default injectIntl(LeftPane);
